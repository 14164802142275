import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  nav: {
    [theme.breakpoints.up('sm')]: {
      position: 'sticky',
      top: '68px',
      width: '100%',
      backgroundColor: '#ffffff',
      zIndex: 50
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '10px',
    padding: '48px 0px 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0px'
    }
  },
  link: {
    color: '#000000',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '33px',
    '&:hover': {
      fontWeight: '600',
      letterSpacing: -0.2
    }
  },
  activeLink: {
    color: '#000000',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '33px'
  },
  divider: {
    marginTop: 0,
    marginBottom: 0,
    color: '#000000',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '33px'
  }
}));

const CategoryTopNavigation = ({ links }) => {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <div className={classes.root}>
        {links.map((link, index) => (
          <React.Fragment key={link?.title}>
            {index !== 0 && <h4 className={classes.divider}>/</h4>}
            <Link
              to={link?.url}
              className={link?.isActive ? classes.activeLink : classes.link}
              underline="none"
            >
              {link?.title}
            </Link>
          </React.Fragment>
        ))}
      </div>
    </nav>
  );
};

CategoryTopNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      mobileTitle: PropTypes.string,
      url: PropTypes.string,
      isActive: PropTypes.bool
    })
  ).isRequired
};

CategoryTopNavigation.defaultProps = {};

export default CategoryTopNavigation;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    position: 'sticky',
    top: '213px',
    color: 'black',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    backgroundColor: 'white',
    zIndex: 10,
    paddingBottom: '15px',
    display: 'inline-flex',
    gap: 4,
    visibility: ({ isStuck }) => (isStuck ? 'visible' : 'hidden')
  }
}));

const SelectedSubcategoryStickyTitle = ({ categories }) => {
  const headerRef = useRef(null);
  const [isStuck, setIsStuck] = useState(false);
  const classes = useStyles({ isStuck });
  const currentCategoryUid = useCategoryOverviewStore(state => state.currentCategoryUid);
  const selectedCategory = useMemo(() => {
    if (!categories || !currentCategoryUid) {
      return null;
    }
    return categories.find(category => category.uid === currentCategoryUid);
  }, [categories, currentCategoryUid]);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerTop = headerRef.current.getBoundingClientRect().top;
        setIsStuck(headerTop <= 213); // Check if the header has reached the sticky point
      }
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  if (!selectedCategory) {
    return null;
  }

  return (
    <h3 className={classes.title} ref={headerRef}>
      {selectedCategory?.displayTitle}{' '}
    </h3>
  );
};

SelectedSubcategoryStickyTitle.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      navigationTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      description: PropTypes.shape({
        html: PropTypes.string
      }),
      calendarDate: PropTypes.string,
      parentCategory: PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      }),
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          uid: PropTypes.string,
          url: PropTypes.string,
          isSubscriberResource: PropTypes.bool,
          urlTarget: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
          resourceMedium: PropTypes.string.isRequired,
          duration: PropTypes.string,
          episodeCountText: PropTypes.string,
          metaText: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            alt: PropTypes.string,
            url: PropTypes.string
          })
        })
      )
    })
  ).isRequired
};

export default SelectedSubcategoryStickyTitle;

import { get } from 'lodash';
import { extractSeoData } from '~utils/unfurl-helpers';
import { extractResourceCards } from '~prismic/unfurl/library-overview';
import { getStringValueOrDefault } from '~utils/data-helper';

const extractParentCategory = parentCategory => {
  if (!parentCategory) {
    return null;
  }

  return {
    uid: get(parentCategory, 'uid', ''),
    title: get(parentCategory, 'document.data.title', '')
  };
};

export const extractCategorySeo = prismicData => {
  const seoData = extractSeoData(prismicData, 'prismicBrowseSection');
  const topicTitle = get(prismicData, 'prismicBrowseSection.data.title', '');
  const defaultSeoTitle = `${topicTitle} Explanations & Meanings`.trim();
  const defaultSeoDescription =
    `Looking to understand ${topicTitle}? Check out Aleph Beta's library of video content which explains the meaning of ${topicTitle}`.trim();

  return {
    ...seoData,
    seo_title: getStringValueOrDefault(seoData.seo_title, defaultSeoTitle),
    seo_description: getStringValueOrDefault(seoData.seo_description, defaultSeoDescription)
  };
};

export default prismicData => ({
  title: get(prismicData, 'prismicBrowseSection.data.title', ''),
  description: get(prismicData, 'prismicBrowseSection.data.description', null),
  coverImage: get(prismicData, 'prismicBrowseSection.data.cover_image', null),
  color: get(prismicData, 'prismicBrowseSection.data.highlight_color', '#000000'),
  landingScrollToCategoryUid: get(
    prismicData,
    'prismicBrowseSection.data.landing_scroll_to_category.uid',
    ''
  ),
  specialLinks: get(prismicData, 'prismicBrowseSection.data.special_links', []).map(link => ({
    title: link?.link_title,
    url: link?.link_url
  })),
  categories: get(prismicData, 'prismicBrowseSection.data.categories', []).map(category => {
    const parentCategory = extractParentCategory(
      get(category, 'category.document.data.parent_category', null)
    );
    const uid = get(category, 'category.document.uid', '');
    return {
      uid,
      navigationTitle: get(category, 'category.document.data.title', ''),
      displayTitle: get(category, 'category.document.data.section_title', ''),
      description: get(category, 'category.document.data.description', null),
      calendarDate: get(category, 'category.document.data.category_date', ''),
      calendarEndDate: get(category, 'category.document.data.category_end_date', ''),
      parentCategory,
      resources: extractResourceCards(get(category, 'category.document.data.resources', []))
    };
  }),
  seo: extractCategorySeo(prismicData)
});

import React, { useMemo } from 'react';
import ReactCalendar from 'react-calendar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: props => ({
    // selected date or start & end date of a date range
    '& .react-calendar__tile--hasActive': {
      backgroundColor: props.color || '#000000',
      color: '#FFFFFF',
      borderRadius: '100%'
    },
    // dates in between the selected 2 dates
    '& .react-calendar__tile--active': {
      backgroundColor: `${props.color || '#000000'}40`,
      borderRadius: '0px'
    },
    // start date of an active date range
    '& .react-calendar__tile--rangeStart': {
      position: 'relative',
      backgroundColor: 'transparent !important',
      borderRadius: '0% !important'
    },
    '& .react-calendar__tile--rangeStart:before': {
      backgroundColor: props.color || '#000000',
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '28px',
      height: '26px',
      borderRadius: '100%',
      zIndex: -1
    },
    '& .react-calendar__tile--rangeStart:after': {
      content: '""',
      position: 'absolute',
      background: `linear-gradient(to right, transparent 50%, ${
        props.color || '#000000'
      } 50%) !important`,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '28px',
      height: '26px',
      zIndex: -2,
      opacity: 0.25
    },
    // end date of an active date range
    '& .react-calendar__tile--rangeEnd': {
      position: 'relative',
      backgroundColor: 'transparent !important',
      borderRadius: '0% !important'
    },
    '& .react-calendar__tile--rangeEnd:before': {
      backgroundColor: props.color || '#000000',
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '28px',
      height: '26px',
      borderRadius: '100%',
      zIndex: -1
    },
    '& .react-calendar__tile--rangeEnd:after': {
      content: '""',
      position: 'absolute',
      background: `linear-gradient(to right, ${
        props.color || '#000000'
      } 50%, transparent 50%) !important`,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '28px',
      height: '26px',
      zIndex: -2,
      opacity: 0.25
    },
    // calendar view
    '& .react-calendar__month-view__weekdays': {
      textAlign: 'center',
      color: '#717171',
      fontSize: 9,
      fontFamily: 'Inter',
      fontWeight: '500',
      '& abbr': {
        textDecoration: 'none'
      }
    },
    // hide navigation
    '& .react-calendar__navigation__arrow': {
      display: 'none'
    },
    '& .react-calendar__navigation__label': {
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      fontSize: 14,
      fontFamily: 'Inter',
      fontWeight: '600'
    },
    '& .react-calendar__navigation': {
      marginBottom: 12
    }
  }),
  tile: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '7px 0px',
    color: '#222222',
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: '500'
  }
}));

const formatShortWeekday = (locale, date) => {
  switch (date.getDay()) {
    case 0:
      return 'S';
    case 1:
      return 'M';
    case 2:
      return 'T';
    case 3:
      return 'W';
    case 4:
      return 'T';
    case 5:
      return 'F';
    case 6:
      return 'S';
    default:
      return '';
  }
};

const Calendar = ({ selectedCategory, color }) => {
  const activeDates = useMemo(() => {
    if (!selectedCategory?.calendarDate) {
      return [];
    }
    if (
      selectedCategory?.calendarEndDate &&
      selectedCategory?.calendarEndDate !== selectedCategory?.calendarDate
    ) {
      return [selectedCategory.calendarDate, selectedCategory.calendarEndDate];
    }
    return [selectedCategory?.calendarDate];
  }, [selectedCategory]);

  const classes = useStyles({ color });

  return (
    <ReactCalendar
      key={activeDates.join('-') || 'empty-calendar'}
      formatShortWeekday={formatShortWeekday}
      calendarType="hebrew"
      minDetail="month"
      maxDetail="month"
      defaultView="month"
      showNeighboringMonth={false}
      className={classes.root}
      tileClassName={classes.tile}
      value={activeDates}
    />
  );
};

Calendar.propTypes = {
  color: PropTypes.string.isRequired,
  selectedCategory: PropTypes.shape({
    calendarDate: PropTypes.string,
    calendarEndDate: PropTypes.string
  })
};

Calendar.defaultProps = {
  selectedCategory: null
};

export default Calendar;

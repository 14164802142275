import { useEffect } from 'react';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';
import { useLocation, navigate } from '@reach/router';

export default (categories, pagePath, landingScrollToCategoryUid) => {
  const [currentCategoryUid, updateCurrentCategoryUid] = useCategoryOverviewStore(state => [
    state.currentCategoryUid,
    state.updateCurrentCategoryUid
  ]);
  const location = useLocation();

  // default navigation to first category
  useEffect(() => {
    if (!currentCategoryUid && categories.length > 0) {
      // let's default to the scroll  to category if it exists after a second delay
      if (landingScrollToCategoryUid && typeof window !== 'undefined') {
        updateCurrentCategoryUid(landingScrollToCategoryUid);
        navigate(`#${landingScrollToCategoryUid}`);
      } else {
        // let's default to the first category if no category is selected
        updateCurrentCategoryUid(categories[0].uid);
      }
    }
  }, [currentCategoryUid, categories, pagePath, updateCurrentCategoryUid]);

  useEffect(() => {
    const urlCategory = location.hash && location.hash.substring(1);
    const selectedCategory = categories.find(category => category.uid === urlCategory);
    if (selectedCategory) {
      updateCurrentCategoryUid(urlCategory);
    }
  }, [location.hash]);

  useEffect(
    () => () => {
      // let's reset the selected category when the component unmounts
      updateCurrentCategoryUid(null);
    },
    [updateCurrentCategoryUid]
  );

  return null;
};

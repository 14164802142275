import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: '5px',
    marginBottom: '30px',
    marginRight: '44px',
    maxWidth: '100%',
    opacity: 0.8,
    '& > *': {
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        lineHeight: '21px'
      }
    },
    '& > p:empty': {
      height: '24px',
      [theme.breakpoints.down('xs')]: {
        height: '21px'
      }
    }
  }
}));

const Description = ({ description }) => {
  const classes = useStyles();

  return (
    <RichText
      html={description && description.html}
      externalClassName={classes.description}
      verticalSpacing={0}
    />
  );
};

Description.propTypes = {
  description: PropTypes.shape({ html: PropTypes.string })
};

Description.defaultProps = {
  description: null
};

export default Description;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import RichText from '~src/components/RichText';

const useStyles = makeStyles(theme => ({
  description: {
    maxWidth: '100%',
    marginRight: 44,
    paddingBottom: 28,
    borderBottom: '1px solid #E0E0E0',
    opacity: 0.8,
    color: '#222222',
    '& > *': {
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '24px'
      }
    },
    '& > p:empty': {
      height: '32px',
      [theme.breakpoints.down('xs')]: {
        height: '24px'
      }
    }
  },
  root: {
    width: '100%',
    marginBottom: 56
  }
}));

const CategoryDesktopDescription = ({ description }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RichText
        html={description.html}
        externalClassName={classes.description}
        verticalSpacing={0}
      />
    </div>
  );
};

CategoryDesktopDescription.propTypes = {
  description: PropTypes.shape({
    html: PropTypes.string
  }).isRequired
};

CategoryDesktopDescription.defaultProps = {};

export default CategoryDesktopDescription;
